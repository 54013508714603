import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
// import Projects from "../components/Projects/Projects"

//TODO gatsby develop -p 80 -H 0.0.0.0 fürs handy
//TODO contentful plugin
//TODO ENV variables (contenful api tokens verstecken) / npm install --save dotenv

const error = props => {
  return (
    <Layout
      title="Du hast dich wohl verlaufen"
      seoTitle="WirtschaftsPlattForm Schwechat | 404"
    >
      <section className="section">
        <div className="container">
          <Link className="button" to="/">
            Ab nach Hause
          </Link>
        </div>
      </section>
    </Layout>
  )
}
export default error
